import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import './style.scss'

class Post extends React.Component {
  render() {
    const {
      title,
      date,
    } = this.props.data.node.frontmatter
    const { slug } = this.props.data.node.fields

    return (
      <div className='post'>
        <time
          className="post-date"
          dateTime={moment(date).format('DD.MM.YYYY')}
        >
          {moment(date).format('DD.MM.YYYY')}
        </time>
        &nbsp;—&nbsp;
        <Link className="post-link" to={slug}>
          {title}
        </Link>
      </div>
    )
  }
}

export default Post
